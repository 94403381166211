import React from 'react';

import '../css/style.css';


function video() {
  // const [videoUrl, setVideoUrl] = useState('');
  // const location = useLocation();
  // const id = localStorage.getItem('items');
  // const get = location.state ? location.state.get : null; // Check if location.state exists
  
  // useEffect(() => {
  //   console.log("EditVideo=" + id);
    
  //   const fetchAudio = async () => {
  //     if (get) { // Ensure get exists before using it
  //       console.log(get.id);
  //       // Fetch data using get...
  //     } else {
  //       console.log("No 'get' data found in the state.");
  //     }
  //   };
  //   fetchAudio();
  // }, [get]);

  return (
    
    <div class="work_user">
        <div class="row">
            <div className='log-user'>
{/* <video controls width="100%" height="100%">
      <source src="/img/Color Burst HDR Dolby Vision™ 12K 60FPS.mp4" type="video/mp4" />
      
      Sorry, your browser doesn't support videos.
    </video> */}
{/* 
<ReactPlayer
        controls={true}
        url={`${API_URL}/api/play/${id}`} // Use backticks for string interpolation
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
        /> */}

</div>
</div>
</div>

    
  )
}

export default video