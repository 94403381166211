export const Movies = [
  {
      name: 'Jetly',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-1.jpg',
      image: 'portfolio-1.jpg',
      category: 'Fantasy',
      language: 'Tamil',
      year: '2022',
      time: '3hr',
      video: '',
      rate: 3.4,
      reviews: 23,
  },
  {
      name: 'Kanidhan',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-2.jpg',
      image: 'portfolio-2.jpg',
      category: 'Thriller',
      language: 'Tamil',
      year: '1999',
      time: '4hr',
      video: '',
      rate: 4,
      reviews: 100,
  },
  {
      name: 'Imaikka Nodigal',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elsed do eiusmod There',
      titleImage: 'portfolio-3.jpg',
      image: 'portfolio-3.jpg',
      category: 'Adventure',
      language: 'Tamil',
      year: '2000',
      time: '11hr',
      video: '',
      rate: 4.5,
      reviews: 2,
  },
  {
      name: 'Baloon',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elsed do eiusmod There',
      titleImage: 'portfolio-4.jpg',
      image: 'portfolio-4.jpg',
      category: 'Horror',
      language: 'Tamil',
      year: '2022',
      time: '5hr',
      video: '',
      rate: 2.5,
      reviews: 6,
  },
  {
      name: '8 Thottakkal',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-5.jpg',
      image: 'portfolio-5.jpg',
      category: 'Action',
      language: 'Tamil',
      year: '1899',
      time: '1hr',
      video: '',
      rate: 3,
      reviews: 56,
  },
  {
      name: 'Isai',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-6.jpg',
      image: 'portfolio-6.jpg',
      category: 'Fantasy',
      language: 'Tamil',
      year: '2012',
      time: '19hr',
      video: '',
      rate: 5,
      reviews: 34,
  },
  {
      name: 'Meen',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-7.jpg',
      image: 'portfolio-7.jpg',
      category: 'Fantasy',
      language: 'Tamil',
      year: '2005',
      time: '4hr',
      video: '',
      rate:2,
      reviews: 49,
  },
  {
      name: 'Jailer',
      desc: 'Lorem ipsum dolor sit amet, consecetur adipiscing elseddo eiusmod There',
      titleImage: 'portfolio-8.jpg',
      image: 'portfolio-8.jpg',
      category: 'Action',
      language: 'Tamil',
      year: '2005',
      time: '4hr',
      video: '',
      rate: 5,
      reviews: 49,
  }

]

export const UsersData = [

  {
    "name": "Tom Hardy",
    "image": "tom_hardy.jpg",
    "role": "Max Rockatansky",
    "bio": "Tom Hardy is a versatile actor known for his roles in various films, including his portrayal of Max Rockatansky in Mad Max: Fury Road.",
    "movies": ["Mad Max: Fury Road", "Inception", "The Dark Knight Rises"],
    "message" :"I can't express how much I love this website! The layout is clean and user-friendly, making it a joy to navigate.",
    "rate":"5"
  },
  {
    "name": "Scarlett Johansson",
    "image": "scarlett_johansson.jpg",
    "role": "Natasha Romanoff / Black Widow",
    "bio": "Scarlett Johansson is a talented actress and producer, best known for her role as Black Widow in the Marvel Cinematic Universe.",
    "movies": ["The Avengers", "Captain America: The Winter Soldier", "Avengers: Endgame"],
    "message" :"The website provides the information I needed, but the layout could be more intuitive.",
    "rate":"5"
  },
  {
    "name": "Leonardo DiCaprio",
    "image": "leonardo_dicaprio.jpg",
    "role": "Cobb",
    "bio": "Leonardo DiCaprio is an award-winning actor known for his exceptional performances. He played the character Cobb in the movie Inception.",
    "movies": ["Inception", "The Revenant", "Titanic"],
    "message" :"Great job to the developers for creating such a fantastic online resource!",
    "rate":"3"
  },
  {
    "name": "Angelina Jolie",
    "image": "angelina_jolie.jpg",
    "role": "Lara Croft",
    "bio": "Angelina Jolie is an iconic actress known for her roles in action films. She portrayed the character Lara Croft in the Tomb Raider series.",
    "movies": ["Lara Croft: Tomb Raider", "Mr. & Mrs. Smith", "Maleficent"],
    "message" :"I appreciate the effort put into creating a reliable and user-friendly platform. Highly recommended!",
    "rate":"4"
  },
  {
    "name": "Robert Downey Jr.",
    "image": "robert_downey_jr.jpg",
    "role": "Tony Stark / Iron Man",
    "bio": "Robert Downey Jr. is a charismatic actor best known for his role as Tony Stark (Iron Man) in the Marvel Cinematic Universe.",
    "movies": ["Iron Man", "The Avengers", "Avengers: Endgame"],
    "message" :"I stumbled upon this website, and it's been a game-changer for me. The design is modern and intuitive, ensuring a seamless user experience. ",
    "rate":"4"
  },
  {
    "name": "Emma Watson",
    "image": "emma_watson.jpg",
    "role": "Hermione Granger",
    "bio": "Emma Watson gained fame for her role as Hermione Granger in the Harry Potter film series. She is also an advocate for gender equality.",
    "movies": ["Harry Potter and the Sorcerer's Stone", "Beauty and the Beast", "The Perks of Being a Wallflower"],
    "message" :"Great job to the developers for creating such a fantastic online resource!",
    "rate":"5"
  },
  {
    "name": "Dwayne Johnson",
    "image": "dwayne_johnson.jpg",
    "role": "Luke Hobbs",
    "bio": "Dwayne Johnson, also known as 'The Rock,' is a popular actor and former professional wrestler. He portrayed Luke Hobbs in the Fast & Furious series.",
    "movies": ["Fast & Furious 6", "Furious 7", "Hobbs & Shaw"],
    "message" :"I've found everything I needed here, and the added features make it stand out from the rest.",
    "rate":"2",
  },
  {
    "name": "Gal Gadot",
    "image": "gal_gadot.jpg",
    "role": "Diana Prince / Wonder Woman",
    "bio": "Gal Gadot is an Israeli actress and model. She gained international fame for her portrayal of Wonder Woman in the DC Extended Universe.",
    "movies": ["Wonder Woman", "Justice League", "Wonder Woman 1984"],
    "message" :"The content is not only informative but also presented in a visually appealing manner.",
    "rate":"1"
  }
  // Add more user data objects as needed
]

